<template>
  <div class="orderDetail center-wrap">
    <div class="big-box" v-if="order">
      <!-- 订单详情 待支付-->
      <div class="order-box" v-if="order.status == 0">
        <div class="order-title">
          <div>
            <h1 v-text="$t('detail.order')">订单详情</h1>
            <p>待支付</p>
          </div>
          <p class="cancel click" v-text="$t('detail.cancel')" @click.stop="orderCancel">取消订单</p>
        </div>
        <div class="order-body">
          <div class="order-time">
            <div class="red-text" v-if="countDownTime">
              <p>{{countDownTime}}</p>
              <div v-text="$t('detail.close')">订单将自动关闭</div>
            </div>
            <div
              class="reason"
              v-if="trans_info && trans_info.status == 2 && trans_info.disagree_reason"
            >
              <p v-text="$t('detail.reason')">转账未通过原因：</p>
              <h2>{{trans_info.disagree_reason}}</h2>
            </div>
          </div>
          <div class="go-botton click" v-text="$t('detail.go_pay')" @click.stop="payOrder">去支付</div>
        </div>
      </div>

      <!-- 待确认时订单详情 -->
      <div class="order-box" v-if="order.status == 6 && trans_info">
        <div class="order-title">
          <div>
            <h1 v-text="$t('detail.order')">订单详情</h1>
            <p>对公转账信息确认中</p>
          </div>
          <p
            class="again click"
            v-text="$t('detail.again')"
            @click.stop="onceAgain(order.item)"
          >再来一单</p>
        </div>
        <div class="order-body">
          <div class="order-time">
            <div class="red-text" v-if="countDownTime">
              <p>{{countDownTime}}</p>
              <div v-text="$t('detail.close')">订单将自动关闭</div>
            </div>
            <div class="reason" v-if="trans_info.status == 2 && trans_info.disagree_reason">
              <p v-text="$t('detail.reason')">转账未通过原因：</p>
              <h2>{{trans_info.disagree_reason}}</h2>
            </div>
          </div>
          <div
            class="go-look click"
            @click.stop="toTransferInfoPage"
            v-text="$t('detail.transfer_information')"
            v-if="trans_info.status == 0 || trans_info.status == 1"
          >查看转账信息</div>
          <div
            class="go-look click"
            v-if="trans_info.status == 2"
            @click.stop="toTransferPage"
          >重新上传转账信息</div>
        </div>
      </div>

      <!-- 代发货时订单详情 -->
      <div class="order-box" v-if="order.status == 1">
        <div class="order-title">
          <div>
            <h1 v-text="$t('detail.order')">订单详情</h1>
            <p>待发货</p>
          </div>
          <p
            class="again click"
            v-text="$t('detail.again')"
            @click.stop="onceAgain(order.item)"
          >再来一单</p>
        </div>
        <div class="order-body" v-if="order.bill_info == null && userinfo && userinfo.local == 1">
          <div class="reason click" @click.stop="useBill = !useBill">
            <img src="../assets/icon/yes-select.png" v-if="useBill" />
            <img src="../assets/icon/no-select.png" v-else />
            <div v-text="$t('detail.invoice')">开发票</div>
          </div>
        </div>
      </div>

      <!-- 待收货时订单详情 -->
      <div class="order-box" v-if="order.status == 2">
        <div class="order-title">
          <div>
            <h1 v-text="$t('detail.order')">订单详情</h1>
            <p>待收货</p>
          </div>
          <p
            class="again click"
            v-text="$t('detail.again')"
            @click.stop="onceAgain(order.item)"
          >再来一单</p>
        </div>
        <div class="order-body">
          <div
            class="reason click"
            @click.stop="useBill = !useBill"
            v-if="order.bill_info == null && userinfo && userinfo.local == 1"
          >
            <img src="../assets/icon/yes-select.png" v-if="useBill" />
            <img src="../assets/icon/no-select.png" v-else />
            <div v-text="$t('detail.invoice')">开发票</div>
          </div>
          <div class="confirm click" v-text="$t('detail.yes_express')" @click.stop="getGoods()">确认收货</div>
        </div>
      </div>

      <!-- 已收货时订单详情 -->
      <div class="orderover-box" v-if="order.status == 3">
        <div class="orderover-title">
          <div>
            <h1 v-text="$t('detail.order')">订单详情</h1>
            <p>已收货</p>
          </div>
          <p
            class="again click"
            v-text="$t('detail.again')"
            @click.stop="onceAgain(order.item)"
          >再来一单</p>
        </div>
        <div class="order-body" v-if="order.bill_info == null && userinfo && userinfo.local == 1">
          <div class="reason click" @click.stop="useBill = !useBill">
            <img src="../assets/icon/yes-select.png" v-if="useBill" />
            <img src="../assets/icon/no-select.png" v-else />
            <div v-text="$t('detail.invoice')">开发票</div>
          </div>
        </div>
      </div>

      <!-- 新增发票信息只有待收货时才有 -->
      <div class="order-box" v-if="useBill && userinfo && userinfo.local == 1">
        <div class="order-title">
          <p
            class="add-bluetext click"
            v-text="$t('detail.add')"
            @click.stop="editBill=null;invoiceMaskShow = true"
          >新增发票信息</p>
          <p class="add-bluetext click submit-btn" @click.stop="getOrderBill()">确定</p>
        </div>
        <div class="invoice-box" v-if="myBillList">
          <div class="add-invoice" v-for="(item,index) in myBillList" :key="'index' + index">
            <img src="../assets/icon/yes-dot.png" v-if="currentbillId == item.id" />
            <img src="../assets/icon/no-dot.png" v-else @click.stop="currentbillId = item.id" />
            <div>
              <div>
                <div>
                  <p v-text="item.title">重庆开发有限公司</p>
                  <p>
                    <span v-text="$t('detail.type')">类型：</span>
                    {{item.type == 1?'企业单位':'个人/非企业单位'}}
                  </p>
                </div>
                <p>
                  <span v-text="$t('detail.taxes_num')">纳税号：</span>
                  {{item.number}}
                </p>
              </div>
              <p>
                <span class="click" @click.stop="fnEditBill(index)">编辑</span>/
                <span class="click" @click.stop="removeBill(index)">删除</span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="order-code">
        <p>
          {{$t('two.num_code')}}
          <span>{{order.order_code}}</span>
        </p>
        <div
          class="click"
          v-clipboard:copy="order.order_code"
          v-clipboard:success="onCopy"
          v-text="$t('two.copy_num_code')"
        >复制订单号</div>
      </div>

      <!-- 商品信息 -->
      <div class="order-box" v-if="order.status > 0">
        <div class="order-title">
          <h1 v-text="$t('detail.commodity')">商品信息</h1>
        </div>
        <div class="book-body">
          <!-- 订单第一本书 -->
          <div
            class="book-money"
            v-for="(product,productIndex) in order.item"
            :key="'productIndex' + productIndex"
          >
            <img
              :src="product.specs_image"
              class="click"
              @click.stop="toProductDetailPage(product.goods_id)"
            />
            <div class="book-name">
              <div class="name-title">{{product.goods_name}}</div>
              <p class="name-body">{{product.specs_name}}</p>
              <div>
                <div v-if="product.goods_discount">{{product.goods_discount}}</div>
              </div>
            </div>
            <div class="money-text">
              <div>
                <div>
                  <p>
                    <span v-text="$t('detail.single')">单价：</span>
                    {{common.showPrice(product.price,product.price_type,false,false,false)}}
                  </p>
                  <h3
                    v-if="userinfo.local != product.price_type && userinfo.local == 1"
                  >{{common.showPrice(common.div(product.price,exchangerate),userinfo.local,false,false,false)}}</h3>
                  <h3
                    v-if="userinfo.local != product.price_type && userinfo.local == 2"
                  >{{common.showPrice(common.mul(product.price,exchangerate),userinfo.local,false,false,false)}}</h3>
                </div>
                <p>×{{product.number}}</p>
              </div>
            </div>
            <div class="percentage-money">
              <p>
                <span v-text="$t('detail.all')">合计：</span>
                {{common.showPrice(common.mul(product.price,product.number),product.price_type,false,false,false)}}
              </p>
              <div
                v-if="userinfo.local != product.price_type && userinfo.local == 1"
              >{{common.showPrice(common.div(common.mul(product.price,product.number),exchangerate),userinfo.local,false,false,false)}}</div>
              <div
                v-if="userinfo.local != product.price_type && userinfo.local == 2"
              >{{common.showPrice(common.mul(common.mul(product.price,product.number),exchangerate),userinfo.local,false,false,false)}}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="order-box" v-for="(item,index) in orderItemList" :key="'i' + index">
          <div class="order-title">
            <h1 v-text="$t('detail.commodity')">商品信息</h1>
          </div>
          <div class="book-body">
            <!-- 订单第一本书 -->
            <div
              class="book-money"
              v-for="(product,proIndex) in item.item"
              :key="'product' + proIndex"
            >
              <img
                :src="product.specs_image"
                class="click"
                @click.stop="toProductDetailPage(product.goods_id)"
              />
              <div class="book-name">
                <div class="name-title">{{product.goods_name}}</div>
                <p class="name-body">{{product.specs_name}}</p>
                <div>
                  <div v-if="product.goods_discount">{{product.goods_discount}}</div>
                </div>
              </div>
              <div class="money-text">
                <div>
                  <div>
                    <p>
                      <span v-text="$t('detail.single')">单价：</span>
                      {{common.showPrice(product.price,product.price_type,false,false,false)}}
                    </p>
                    <h3
                      v-if="userinfo.local != product.price_type && userinfo.local == 1"
                    >{{common.showPrice(common.div(product.price,exchangerate),userinfo.local,false,false,false)}}</h3>
                    <h3
                      v-if="userinfo.local != product.price_type && userinfo.local == 2"
                    >{{common.showPrice(common.mul(product.price,exchangerate),userinfo.local,false,false,false)}}</h3>
                  </div>
                  <p>×{{product.number}}</p>
                </div>
              </div>
              <div class="percentage-money">
                <p>
                  <span v-text="$t('detail.all')">合计：</span>
                  {{common.showPrice(common.mul(product.price,product.number),product.price_type,false,false,false)}}
                </p>
                <div
                  v-if="userinfo.local != product.price_type && userinfo.local == 1"
                >{{common.showPrice(common.div(common.div(product.price,product.number),exchangerate),userinfo.local,false,false,false)}}</div>
                <div
                  v-if="userinfo.local != product.price_type && userinfo.local == 2"
                >{{common.showPrice(common.mul(common.mul(product.price,product.number),exchangerate),userinfo.local,false,false,false)}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 满减优惠 -->
      <div class="preferential-box" v-if="order.status > 0">
        <div>
          <div class="preferential">
            <div>
              <h4 v-text="$t('detail.commodity_all')">商品合计：</h4>
              <p>{{common.showPrice(order.total,userinfo.local,false,false,false)}}</p>
            </div>
            <div>
              <h4 v-text="$t('detail.freight')">运费：</h4>
              <p>{{common.showPrice(order.freight_total,userinfo.local,false,false,false)}}</p>
            </div>

            <div v-if="order.discount_total > 0">
              <h4 v-text="$t('detail.discount')">优惠：</h4>
              <div class="preferential-add">
                <div v-for="(d,dindex) in order.total_arr.discount_text_arr" :key="'dis' + dindex">
                  <div>{{d.text}}</div>
                  <p
                    v-if="(dindex +1)== order.total_arr.discount_text_arr.length"
                  >-{{common.showPrice(order.discount_total,userinfo.local,false,false,false)}}</p>
                </div>
              </div>
            </div>

            <div>
              <h4 v-text="$t('detail.discount_code')">优惠码：</h4>
              <p>-{{common.showPrice(order.discount_code_money,userinfo.local,false,false,false)}}</p>
            </div>
          </div>
          <div class="preferential-money">
            <div>
              <h4 v-text="$t('detail.all')">合计：</h4>
              <p>{{common.showPrice(order.pay_total,userinfo.local,false,false,false)}}</p>
            </div>
            <div v-if="order.status > 0">
              <h4 v-text="$t('detail.pay_mode')">支付方式：</h4>
              <div v-if="order.pay_type == 1">微信支付</div>
              <div v-if="order.pay_type == 2">支付宝</div>
              <div v-if="order.pay_type == 3">余额付款</div>
              <div v-if="order.pay_type == 4">对公转账</div>
            </div>
          </div>
        </div>
      </div>
      <div class="preferential-box" v-if="order.status ==0 && orderItemList.length">
        <div>
          <div class="preferential">
            <div>
              <h4 v-text="$t('detail.commodity_all')">商品合计：</h4>
              <p>{{common.showPrice(orderItemList[0].total_arr.allTotal,userinfo.local,false,false,false)}}</p>
            </div>
            <div>
              <h4 v-text="$t('detail.freight')">运费：</h4>
              <p>{{common.showPrice(orderItemList[0].total_arr.allFreightTotal,userinfo.local,false,false,false)}}</p>
            </div>
            <div
              v-if="orderItemList[0].total_arr.discount_text_arr && orderItemList[0].total_arr.discount_text_arr.length > 0"
            >
              <h4 v-text="$t('detail.discount')">优惠：</h4>
              <div class="preferential-add">
                <div
                  v-for="(d,dindex) in orderItemList[0].total_arr.discount_text_arr"
                  :key="'item' + dindex"
                >
                  <div>{{d.text}}</div>
                </div>
              </div>
            </div>

            <div>
              <h4 v-text="$t('detail.discount_code')">优惠码：</h4>
              <p>-{{common.showPrice(order.discount_code_money,userinfo.local,false,false,false)}}</p>
            </div>
          </div>
          <div class="preferential-money">
            <div>
              <h4 v-text="$t('detail.all')">合计：</h4>
              <p>{{common.showPrice(orderItemList[0].total_arr.payTotal,userinfo.local,false,false,false)}}</p>
            </div>
            <div v-if="order.status > 0">
              <h4 v-text="$t('detail.pay_mode')">支付方式：</h4>
              <div v-if="order.pay_type == 1">微信支付</div>
              <div v-if="order.pay_type == 2">支付宝</div>
              <div v-if="order.pay_type == 3">余额付款</div>
              <div v-if="order.pay_type == 4">对公转账</div>
            </div>
          </div>
        </div>
      </div>

      <!-- 收货地址 -->
      <div class="address-box">
        <div class="order-title">
          <h1 v-text="$t('detail.adderss')">收货地址</h1>
          <h5 v-text="$t('detail.invoice_information')" v-if="order.bill_info">发票信息</h5>
        </div>
        <div class="goods">
          <!-- 收货地址信息 -->
          <div class="address">
            <div>
              <p v-text="$t('detail.people')">收货人：</p>
              <h2>{{order.address_info.name}}</h2>
            </div>
            <div>
              <p v-text="$t('detail.number')">联系方式：</p>
              <h2>{{order.address_info.mobile}}</h2>
            </div>
            <div>
              <p v-text="$t('detail.adderss')">收货地址：</p>
              <h2>{{order.address_info.province + order.address_info.city + order.address_info.area + order.address_info.address}}</h2>
            </div>
            <div v-if="order.transport_company">
              <p v-text="$t('detail.transport_company')">物流公司：</p>
              <h2>{{order.transport_company | showTransportCompany}}</h2>
            </div>
            <div v-if="order.transport_code">
              <p v-text="$t('detail.transport_code')">物流单号：</p>
              <h2>{{order.transport_code}}</h2>
            </div>
          </div>
          <!-- 订单物流信息 -->
          <div v-if="transportInfo">
            <div class="state">
              <div v-for="(item,index) in transportInfo" :key="index">
                <p>{{item.time}}:</p>
                <div class="state-div">
                  <h2>{{item.context}}</h2>
                </div>
              </div>
            </div>
          </div>
          <div class="state" v-else>
            <div>{{ $t('order.logistics_empty')}}</div>
          </div>

          <!-- 发票信息待发货待收货没有 -->
          <div class="invoice" v-if="order.bill_info">
            <div>
              <h2>{{order.bill_info.title}}</h2>
            </div>
            <div>
              <p v-text="$t('detail.type')">类型：</p>
              <h2>{{order.bill_info.type == 1?'企业':'个人'}}</h2>
            </div>
            <div>
              <p v-text="$t('detail.taxes_num')" v-if="order.bill_info.type == 1">纳税号：</p>
              <h2>{{order.bill_info.number}}</h2>
            </div>
            <div>
              <p v-text="$t('detail.bill_reason')">原因：</p>
              <h2>{{order.bill_info.disagree_reason}}</h2>
            </div>
            <div class="blue-botton" v-if="order.bill_info.status == 0">待处理</div>
            <div class="blue-botton" v-if="order.bill_info.status == 1">已通过</div>
            <div class="red-botton" v-if="order.bill_info.status == 2">未通过</div>
          </div>
        </div>
      </div>

      <!-- 备注 -->
      <h1 v-text="$t('detail.remarks')">订单备注</h1>
      <div class="textarea-box">
        <textarea readonly v-model="order.remark"></textarea>
      </div>

      <!-- 未支付订单 选择支付方式 -->
      <div v-if="order.status == 0 && orderItemList.length">
        <div class="mode-payment">
          <div class="log-box">
            <!-- 微信支付 -->
            <div class="click" @click.stop="payType = 'wxpay'" v-show="wxPayEnable">
              <img src="../assets/icon/yes-dot.png" class="dot" v-if="payType == 'wxpay'" />
              <img src="../assets/icon/no-dot.png" class="dot" v-else />
              <img src="../assets/icon/wx.png" class="logo" />
              <p v-text="$t('order.wxpay')">微信支付</p>
            </div>
            <!-- 支付宝 -->
            <div class="click" @click.stop="payType = 'alipay'" v-show="aliPayEnable">
              <img src="../assets/icon/yes-dot.png" class="dot" v-if="payType == 'alipay'" />
              <img src="../assets/icon/no-dot.png" class="dot" v-else />
              <img src="../assets/icon/zfb.png" class="logo" />
              <p v-text="$t('order.alipay')">支付宝支付</p>
            </div>
            <!-- 余额 -->
            <div class="click" @click.stop="payType = 'balance'" v-show="balancePayEnable">
              <img src="../assets/icon/yes-dot.png" class="dot" v-if="payType == 'balance'" />
              <img src="../assets/icon/no-dot.png" class="dot" v-else />
              <img src="../assets/icon/balance.png" class="logo" />
              <!-- <p v-text="$t('order.balance_pay')">余额支付</p> -->
              <div class="balance-value">
                <p>{{$t('order.balance_pay')}}</p>
                <p>¥ {{balance}}</p>
              </div>
            </div>
            <!-- 对公转账 -->
            <div class="click" @click.stop="payType = 'transfer'" v-show="transferPayEnable">
              <img src="../assets/icon/yes-dot.png" class="dot" v-if="payType == 'transfer'" />
              <img src="../assets/icon/no-dot.png" class="dot" v-else />
              <img src="../assets/icon/transfer.png" class="logo" />
              <p v-text="$t('order.transfer')">对公转账</p>
            </div>
            <!-- 信用卡 -->
            <div class="click" @click.stop="payType = 'creditCard'" v-show="creditCardPayEnable">
              <img src="../assets/icon/yes-dot.png" class="dot" v-if="payType == 'creditCard'" />
              <img src="../assets/icon/no-dot.png" class="dot" v-else />
              <img src="../assets/icon/xyk.png" class="logo" />
              <p v-text="$t('order.creditCard')">信用卡</p>
            </div>

            <div class="click" @click="payType = 'pinganPay'" v-show="pinganPayEnable">
              <img src="../assets/icon/yes-dot.png" class="dot" v-if="payType == 'pinganPay'" />
              <img src="../assets/icon/no-dot.png" class="dot" v-else />
              <img src="../assets/icon/transfer.png" class="logo" />
              <p v-text="$t('order.pinganPay')">平安支付</p>
            </div>
          </div>
        </div>
        <!-- <p class="explain" v-if="aliPayEnable || creditCardPayEnable">使用Alipay或信用卡支付可能產生額外手續費。</p> -->
        <!-- <p class="explain">{{$t('order.explain_before')}}{{pay_max/1}}{{$t('order.explain_after')}}</p> -->
      </div>
      <!-- 联系客服,待支付状态不显示 -->
      <div class="service-state" v-if="order.status > 0">
        <div class="service-box click" @click.stop="fnContactService">
          <img src="../assets/icon/service.png" />
          <p v-text="$t('detail.service')">联系客服</p>
        </div>
        <div class="service-time">
          <!-- 订单创建时间 -->
          <div>
            <p class="time-title" v-text="$t('detail.submit_time')">提交时间：</p>
            <p class="time-body">{{order.create_time}}</p>
          </div>
          <!-- 订单支付时间 -->
          <div v-if="order.pay_time">
            <p class="time-title" v-text="$t('detail.pay_time')">支付时间：</p>
            <p class="time-body">{{order.pay_time}}</p>
          </div>
          <!-- 对公转账确认时间 -->
          <div v-if="order.confirm_transfer_time">
            <p class="time-title" v-text="$t('detail.ok_time')">确认时间：</p>
            <p class="time-body">{{order.confirm_transfer_time}}</p>
          </div>
          <!-- 訂單關閉时间 -->
          <div v-if="order.close_time">
            <p class="time-title" v-text="$t('detail.close_time')">訂單關閉时间：</p>
            <p class="time-body">{{order.close_time}}</p>
          </div>
          <!-- 发货时间 -->
          <div v-if="order.send_time">
            <p class="time-title" v-text="$t('detail.dispatch_time')">发货时间：</p>
            <p class="time-body">{{order.send_time}}</p>
          </div>
          <!-- 收货时间 -->
          <div v-if="order.get_time">
            <p class="time-title" v-text="$t('detail.receive_time')">收货时间：</p>
            <p class="time-body">{{order.get_time}}</p>
          </div>
        </div>
      </div>
      <!-- 结算 -->
      <!-- 此div只在待支付状态显示 -->
      <div class="payment" v-if="order.status == 0 && orderItemList.length">
        <div class="payment-text">
          <p class="payment-redtext">{{countDownTime}}</p>
          <p v-text="$t('detail.close')" v-if="countDownTime">订单将自动关闭</p>
        </div>
        <div class="payment-botton">
          <div>
            <p>{{common.showPrice(common.add(orderItemList[0].total_arr.payTotal,orderPayServiceFee),userinfo.local,false,false,false)}}</p>
            <div>{{orderPayServiceFee?$t('detail.service_charge')+orderPayServiceFee:$t('detail.all')}}</div>
          </div>
          <p v-text="this.$t('detail.go_pay')" class="click" @click.stop="payOrder">去支付</p>
        </div>
      </div>
    </div>
    <div class="wechat-qrcode-box" v-if="showWechatPayMask">
      <div class="wechat-qrcode-content">
        <img src="../assets/icon/icon-close.png" class="close-icon" @click.stop="cancelPay" />
        <p v-text="$t('order.wxpay')">微信支付</p>
        <div class="qecode-box" ref="qrcode"></div>
      </div>
    </div>
    <!-- 发票蒙层 -->
    <v-invoice-mask
      @close="invoiceMaskShow = false"
      v-if="invoiceMaskShow"
      @addSuccess="addSuccess"
      :editBill="editBill"
      @editSuccess="editBillSuccess"
    ></v-invoice-mask>
    <v-pay-password-mask
      @close="cancelPay"
      @fnForget="handleForget"
      v-if="payPasswordMaskShow"
      @payPasswordSubmit="balancePayOrderSubmit"
    ></v-pay-password-mask>
    <v-set-pay-pwd-mask
      @close="cancelPay"
      v-if="setPasswordMaskShow"
      @setPayPwdSuccess="setPayPwdSuccess"
    ></v-set-pay-pwd-mask>

    <el-dialog
      :title="$t('order.selectCard')"
      :visible.sync="pinganPayDialogVisible"
      width="350px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-select v-model="pinganPayCardValue">
        <el-option label="ICBC 工商银行" value="ICBC" />
        <el-option label="ABC 农业银行" value="ABC" />
        <el-option label="BOC 中国银行" value="BOC" />
        <el-option label="CCB 建设银行" value="CCB" />
        <el-option label="CMB 招商银行" value="CMB" />
        <el-option label="GDB 广发银行" value="GDB" />
        <el-option label="CMBC 民生银行" value="CMBC" />
        <el-option label="CEB 光大银行" value="CEB" />
        <el-option label="HXB 华夏银行" value="HXB" />
        <el-option label="PSBC 邮政储蓄" value="PSBC" />
        <el-option label="BCCB 北京银行" value="BCCB" />
        <el-option label="PAB 平安银行" value="PAB" />
        <el-option label="SPDB 浦发银行" value="SPDB" />
        <el-option label="CZSB 浙商银行" value="CZSB" />
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onPinganPayConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { MessageBox } from 'element-ui'
import InvoiceMask from '../components/invoiceMask.vue'
import QRCode from 'qrcodejs2'
import PayPasswordMask from '../components/payPasswordMask.vue'
import SetPayPwdMask from '../components/setPayPwdMask.vue'

export default {
  name: 'OrderDetail',
  data() {
    return {
      order: null,
      orderItemList: [], // 未付款订单显示的列表
      countDownTime: null, // 剩余支付时间
      userinfo: JSON.parse(sessionStorage.getItem('userinfo')),
      exchangerate: localStorage.getItem('exchangerate'), // 币种汇率
      hk_ali_serviceFee: localStorage.getItem('hk_ali_serviceFee'), // 香港支付宝支付手续费
      hk_wx_serviceFee: localStorage.getItem('hk_wx_serviceFee'), // 香港微信支付手续费
      ali_serviceFee: localStorage.getItem('ali_serviceFee'), // 大陆支付宝支付手续费
      wx_serviceFee: localStorage.getItem('wx_serviceFee'), // 大陆微信支付手续费
      pay_max: localStorage.getItem('pay_max'), // 支付宝微信最大支付金额
      paypal_serviceFee: localStorage.getItem('paypal_serviceFee'), // 支付宝微信最大支付金额
      useBill: false, //发票所需属性
      myBillList: null,
      invoiceMaskShow: false,
      currentbillId: null,
      editBill: null,
      editBillIndex: null,
      payType: null, // wxpay:微信支付  alipay:支付宝支付 balance:余额支付 transfer:对公转账
      orderPayServiceFee: 0, // 支付手续费
      balance: 0, // 我的账户余额
      showWechatPayMask: false, // 微信支付二维码蒙层
      payPasswordMaskShow: false,
      setPasswordMaskShow: false,
      inter: null,
      trans_info: null, // 订单对公转账信息
      transportInfo: null, // 订单物流信息

      // 选择银行卡弹窗
      pinganPayDialogVisible: false,
      pinganPayCardValue: null,
    }
  },
  computed: {
    // 是否启用微信支付
    wxPayEnable: function () {
      // 大陆和香港用户都隐藏微信支付
      return false
    },
    // 是否启用支付宝支付
    aliPayEnable: function () {
      //大陆用户隐藏支付宝支付 香港用户启用支付宝
      return this.userinfo && this.userinfo.local == 2
    },
    // 是否启用余额支付
    balancePayEnable: function () {
      // 香港用户禁用余额支付 大陆用户启用余额支付
      // return this.userinfo && this.userinfo.local == 1
      return false
    },
    // 是否启用平安支付 替换对公转账
    pinganPayEnable: function () {
      return this.userinfo && this.userinfo.local == 1
    },
    // 是否启用对公转账支付
    transferPayEnable: function () {
      // 香港用户禁用余额公转账付 大陆用户启用公转账支付
      return this.userinfo && this.userinfo.local == 1
    },
    // 是否启用信用卡支付
    creditCardPayEnable: function () {
      // 香港用户可以使用支付宝香港 和信用卡支付
      return this.userinfo && this.userinfo.local == 2
    },
  },
  created() {
    this.httpOrderDetail()
  },
  watch: {
    useBill: function () {
      if (this.useBill && this.myBillList == null) {
        this.httpMyBillList()
      }
    },
    /*支付方式*/
    payType: function () {
      if (this.payType == 'transfer') {
        this.orderPayServiceFee = 0
      } else if (this.payType == 'wxpay') {
        if (this.userinfo.local == 1)
          this.orderPayServiceFee = this.common.mul(
            this.orderItemList[0].total_arr.payTotal,
            this.wx_serviceFee
          )
        if (this.userinfo.local == 2)
          this.orderPayServiceFee = this.common.mul(
            this.orderItemList[0].total_arr.payTotal,
            this.hk_wx_serviceFee
          )
        this.orderPayServiceFee = this.orderPayServiceFee.toFixed(2) / 1
      } else if (this.payType == 'alipay') {
        if (this.userinfo.local == 1)
          this.orderPayServiceFee = this.common.mul(
            this.orderItemList[0].total_arr.payTotal,
            this.ali_serviceFee
          )
        if (this.userinfo.local == 2)
          this.orderPayServiceFee = this.common.mul(
            this.orderItemList[0].total_arr.payTotal,
            this.hk_ali_serviceFee
          )
        this.orderPayServiceFee = this.orderPayServiceFee.toFixed(2) / 1
      } else if (this.payType == 'balance') {
        this.orderPayServiceFee = 0
        if (this.balance < this.orderItemList[0].total_arr.payTotal) {
          this.common.showMessage(this.$t('order.balance_bad'), 'error') //余额不足
          this.payType = null
        }
      } else if (this.payType == 'creditCard') {
        this.orderPayServiceFee = this.common.mul(
          this.orderItemList[0].total_arr.payTotal,
          this.paypal_serviceFee
        )
      }
    },
  },
  methods: {
    // 复制成功时的回调函数
    onCopy(e) {
      this.common.showMessage(this.$t('two.copy')) //"订单号已复制"
    },

    payOrder: function () {
      var self = this
      if (!this.payType)
        return this.common.showMessage(self.$t('order.choice_pay'), 'error') //请选择支付方式

      if (self.payType == 'alipay' && self.userinfo.local == 1) {
        self.aliPayOrder(this.order.order_code)
      } else if (self.payType == 'alipay' && self.userinfo.local == 2) {
        self.aliPayOrderHK(this.order.order_code)
      } else if (self.payType == 'creditCard') {
        self.paypalOrder(this.order.order_code)
      } else if (self.payType == 'wxpay') {
        self.wxPayOrder(this.order.order_code)
      } else if (self.payType == 'balance') {
        self.balancePayOrder(this.order.order_code)
      } else if (self.payType == 'transfer') {
        self.toTransferPage()
      } else if (self.payType == 'pinganPay') {
        this.pinganPayDialogVisible = true
      }
    },

    // 平安支付
    onPinganPayConfirm: function () {
      var self = this
      if (!this.pinganPayCardValue)
        return this.common.showMessage(this.$t('order.selectCard'), 'error')
      this.pinganPayDialogVisible = false

      var newWindow = window.open(
        'about:blank',
        '',
        'width=1000,height=850,scrollbars=yes,resizable=1'
      )

      if (newWindow == null || typeof newWindow == 'undefined')
        return alert('关闭您的弹出窗口拦截器！')

      var host =
        process.env.NODE_ENV === 'development'
          ? 'http://bookshop.yangkaiq.cn'
          : 'http://' + window.location.host
      var path = `${host}/api/ajax/pingAnOrder?order_code=${this.order.order_code}&bank_code=${this.pinganPayCardValue}`
      newWindow.location.href = path

      MessageBox({
        title: this.$t('order.pay_pop'), //提示
        message: this.$t('order.bank_pay'), //正在使用支付宝付款...
        confirmButtonText: this.$t('order.over_pay'), //完成支付
        showCancelButton: false,
        cancelButtonText: this.$t('order.pay_bad'), //支付遇到问题
        type: 'warning',
        callback(action) {
          clearInterval(self.inter)
          self.$router.replace('/order/list')
        },
      })
    },

    toTransferPage: function () {
      var self = this
      self.common
        .httpPost(
          '/api/ajax/aliPayOrder?order_code=' + self.order.order_code,
          {}
        )
        .then((ret) => {
          // console.log(ret);
          if (ret.code == 1) {
            self.$router.push({
              path: '/transfer',
              query: { order_code: self.order.order_code },
            })
          } else self.common.showMessage(ret.msg, 'error')
        })
    },
    cancelPay: function () {
      this.showWechatPayMask = false
      this.setPasswordMaskShow = false
      this.payPasswordMaskShow = false
      if (this.inter) clearInterval(this.inter)
    },

    aliPayOrder: function (order_code) {
      var self = this
      self.common
        .httpPost('/api/ajax/aliPayOrder?order_code=' + order_code, {})
        .then((ret) => {
          if (ret.code == 1) {
            var newWindow = window.open(
              'about:blank',
              '',
              'width=1000,height=850,scrollbars=yes,resizable=1'
            )
            if (newWindow == null || typeof newWindow == 'undefined')
              return alert('关闭您的弹出窗口拦截器！')
            newWindow.location.href =
              'https://' +
              window.location.host +
              '/api/ajax/aliPayOrder?order_code=' +
              order_code
            MessageBox({
              title: self.$t('order.pay_pop'), //提示
              message: self.$t('order.zfb_pay'), //正在使用支付宝付款...
              confirmButtonText: self.$t('order.over_pay'), //完成支付
              showCancelButton: false,
              cancelButtonText: self.$t('order.pay_bad'), //支付遇到问题
              type: 'warning',
              callback(action) {
                self.httpOrderDetail()
              },
            })
          } else self.common.showMessage(ret.msg, 'error')
        })
    },

    // 支付寶 支付 香港
    aliPayOrderHK: function (order_code) {
      var newWindow = window.open(
        'about:blank',
        '',
        'width=1000,height=850,scrollbars=yes,resizable=1'
      )
      if (newWindow == null || typeof newWindow == 'undefined')
        return alert('关闭您的弹出窗口拦截器！')

      var self = this
      let routerData = this.$router.resolve({
        path: '/order/alipay_hk',
        query: { order_code: order_code },
      })
      newWindow.location.href = routerData.href

      MessageBox({
        title: self.$t('order.pay_pop'), //提示
        message: self.$t('order.zfb_pay'), //正在使用支付宝付款...
        confirmButtonText: self.$t('order.over_pay'), //完成支付
        showCancelButton: false,
        cancelButtonText: self.$t('order.pay_bad'), //支付遇到问题
        type: 'warning',
        callback(action) {
          self.httpOrderDetail()
        },
      })
    },

    // paypal支付
    paypalOrder: function (order_code) {
      var newWindow = window.open(
        'about:blank',
        '',
        'width=1000,height=850,scrollbars=yes,resizable=1'
      )
      if (newWindow == null || typeof newWindow == 'undefined')
        return alert('关闭您的弹出窗口拦截器！')

      var self = this
      self.common
        .httpPost('/api/ajax/payPalOrder', {
          order_code: order_code,
        })
        .then((ret) => {
          if (ret.code == 1) {
            var url = ret.data
            newWindow.location.href = url

            MessageBox({
              title: self.$t('order.pay_pop'),
              message: self.$t('order.paypal_pay'),
              confirmButtonText: self.$t('order.over_pay'),
              showCancelButton: false,
              cancelButtonText: self.$t('order.pay_bad'),
              type: 'warning',
              callback(action) {
                self.httpOrderDetail()
              },
            })
          } else {
            newWindow.close()
            self.common.showMessage(ret.msg, 'error')
          }
        })
    },
    wxPayOrder: function (order_code) {
      var self = this
      self.common
        .httpPost('/api/order/payOrder', {
          order_code: order_code,
          pay_type: 1,
        })
        .then((ret) => {
          if (ret.code == 1) {
            self.showWechatPayMask = true
            var url = ret.data
            self.$nextTick(function () {
              new QRCode(this.$refs.qrcode, {
                text: url,
                width: 200,
                height: 200,
                colorDark: '#000000',
                colorLight: '#ffffff',
                correctLevel: QRCode.CorrectLevel.H,
              })
              setTimeout(function () {
                self.httpLoop(order_code)
              }, 3000)
            })
          } else self.common.showMessage(ret.msg, 'error')
        })
    },
    balancePayOrder: function (order_code) {
      var self = this
      // 是否设置了支付密码
      self.userHasPayPassword().then((ret) => {
        if (ret.code == 1) {
          if (ret.data == 1) {
            this.payPasswordMaskShow = true
          } else {
            this.setPasswordMaskShow = true
          }
        } else self.common.showMessage(ret.msg, 'error')
      })
    },
    /**
     * 余额支付 附带支付密码提交
     */
    balancePayOrderSubmit: function (password) {
      var self = this
      self.common
        .httpPost('/api/order/payOrder', {
          order_code: self.order.order_code,
          pay_type: 3,
          pay_password: password,
        })
        .then((ret) => {
          self.common.showMessage(ret.msg, ret.code == 1 ? 'success' : 'error')
          if (ret.code == 1) {
            self.showWechatPayMask = false
            self.setPasswordMaskShow = false
            self.payPasswordMaskShow = false
            clearInterval(self.inter)

            self.httpOrderDetail()
          }
        })
    },
    httpLoop: function (order_code) {
      var self = this
      self.inter = setInterval(function () {
        self.common
          .httpPost(
            '/api/user/checkOrderStatus',
            { order_code, order_code },
            false
          )
          .then((ret) => {
            if (ret.code == 1 && ret.data == 1) {
              self.showWechatPayMask = false
              self.setPasswordMaskShow = false
              self.payPasswordMaskShow = false
              clearInterval(self.inter)

              self.httpOrderDetail()
            }
          })
      }, 2000)
    },
    /* 订单增开发票*/
    getOrderBill: function () {
      var self = this
      if (!self.currentbillId)
        return self.common.showMessage(self.$t('order.open_bill'), 'error') //请选择开票信息
      self.common
        .httpPost('/api/order/getOrderBill', {
          order_code: self.order.order_item_code,
          bill_id: self.currentbillId,
        })
        .then((ret) => {
          self.common.showMessage(ret.msg, ret.code == 1 ? 'success' : 'error')
          if (ret.code == 1) {
            location.reload()
          }
        })
    },
    getGoods: function () {
      var self = this
      MessageBox({
        title: self.$t('order.pay_pop'), //提示
        message: self.$t('order.collect'), //您确定已经收到货了吗?
        confirmButtonText: self.$t('cart.define'), //确定
        showCancelButton: true,
        cancelButtonText: self.$t('cart.cancel'), //取消
        type: 'warning',
        callback(action) {
          if (action === 'confirm') {
            self.common
              .httpPost('/api/user/getGoods', {
                id: self.order.id,
              })
              .then((ret) => {
                self.common.showMessage(
                  ret.msg,
                  ret.code == 1 ? 'success' : 'error'
                )
                if (ret.code == 1) {
                  self.httpOrderDetail()
                }
              })
          }
        },
      })
    },
    /*取消订单*/
    orderCancel: function () {
      var self = this
      MessageBox({
        title: self.$t('order.pay_pop'), //提示
        message: self.$t('order.no_order'), //您确定取消该订单吗?
        confirmButtonText: self.$t('cart.define'), //确定
        showCancelButton: true,
        cancelButtonText: self.$t('cart.cancel'), //取消
        type: 'warning',
        callback(action) {
          if (action === 'confirm') {
            self.common
              .httpPost('/api/user/cancelOrder', {
                id: self.order.id,
              })
              .then((ret) => {
                self.common.showMessage(
                  ret.msg,
                  ret.code == 1 ? 'success' : 'error'
                )
                if (ret.code == 1) {
                  self.$router.replace('/order/list')
                }
              })
          }
        },
      })
    },
    toTransferInfoPage() {
      this.$router.push({
        path: '/transfer/info',
        query: { order_code: this.order.order_code },
      })
    },
    /**
     * 请求订单分单详情
     */
    httpOrderDetail: function () {
      var self = this
      self.common
        .httpPost('/api/user/orderDetail', {
          id: self.$route.query.id,
        })
        .then((ret) => {
          if (ret.code == 1) {
            self.order = ret.data
            if (self.order.status == 0 || self.order.status == 6) {
              self.startCountDownPayTime()
              self.getTransferInfo()
            }
            if (self.order.status == 0) {
              // 请求订单总单信息
              self.orderInfo()
              self.httpMyBalance()
            }
            if (self.order.transport_code && self.order.transport_company) {
              self.httpTransportInfo(
                self.order.transport_code,
                self.order.transport_company
              )
            }
          } else self.common.showMessage(ret.msg)
        })
    },
    /**
     * 订单对公转账信息
     * @return {[type]} [description]
     */
    getTransferInfo: function () {
      var self = this
      self.common
        .httpPost('/api/user/getTransferInfo', {
          order_code: self.order.order_code,
        })
        .then((ret) => {
          self.trans_info = ret.data
        })
    },
    /* 请求订单总单信息 */
    orderInfo: function () {
      var self = this
      self.common
        .httpPost('/api/user/orderInfo', {
          id: self.$route.query.id,
        })
        .then((ret) => {
          if (ret.code == 1) {
            self.orderItemList = ret.data
          } else self.common.showMessage(ret.msg)
        })
    },
    /** 未支付的订单 剩余时间倒计时 */
    startCountDownPayTime() {
      var self = this
      var createTime = this.order.create_time
      var createTimeDate = this.common.parseDate(
        createTime,
        'yyyy-MM-dd HH:mm:ss'
      )
      // var lastPayDate = new Date(createTimeDate.getTime() + (1000 * 60 * 10));
      var lastPayDate = this.common.addDay(createTimeDate, 3) // 最后支付时间
      // 如果倒计时时间已经超时 那么不执行下面的倒计时代码
      if (lastPayDate.getTime() < new Date().getTime()) return

      var seconds = this.common.countSeconds(lastPayDate, new Date())
      var inter = setInterval(() => {
        if (seconds <= 0) {
          self.countDownTime = null
          clearInterval(inter)
        } else {
          self.countDownTime = this.common.formatTimeToFriendly(seconds)
          seconds--
        }
      }, 1000)
    },
    /**
     * 请求我的余额
     */
    httpMyBalance() {
      var self = this
      this.common.httpPost('/api/user/getMyBalance', {}).then((ret) => {
        if (ret.code == 1) {
          self.balance = ret.data
        }
      })
    },
    /**
     * 跳转到商品详情页面
     * @param  {[type]} product_id [商品id]
     * @return {[type]}            [description]
     */
    toProductDetailPage(product_id) {
      var self = this
      this.proIsUpperShelf(product_id, function () {
        self.$router.push({
          path: '/product',
          query: {
            product_id: product_id,
          },
        })
      })
    },
    // 产品是否上架
    proIsUpperShelf: function (id, callback) {
      this.common
        .httpPost('/api/ajax/getGoodsStatus', { id: id })
        .then((ret) => {
          if (ret.code == 1) {
            if (!ret.data) {
              this.common.showMessage(this.$t('list.pro_error'), 'error')
            } else {
              callback && callback()
            }
          } else this.common.showMessage(ret.msg, 'error')
        })
    },
    /*再来一单*/
    onceAgain: function (productList) {
      var params = []
      productList.forEach(function (item) {
        params.push({
          specs_id: item.specs_id,
          number: item.number,
        })
      })
      this.$router.push({
        path: '/orderConfirm',
        query: {
          params: JSON.stringify(params),
        },
      })
    },
    /**
     * 请求我的发票信息列表
     */
    httpMyBillList: function () {
      var self = this
      self.common.httpPost('/api/user/myBillList').then((ret) => {
        if (ret.code == 1) {
          self.myBillList = ret.data
        }
      })
    },
    /**
     * 添加发票
     */
    addSuccess: function (bill) {
      this.invoiceMaskShow = !1
      this.myBillList.push(bill)
    },
    fnEditBill: function (index) {
      this.editBill = this.myBillList[index]
      this.editBillIndex = index
      this.invoiceMaskShow = true
    },
    editBillSuccess: function (bill) {
      this.invoiceMaskShow = !1
      this.$set(this.myBillList, this.editBillIndex, bill)
      this.editBill = null
      this.editBillIndex = null
    },
    /**
     * 删除发票
     */
    removeBill: function (index) {
      var self = this
      MessageBox({
        title: self.$t('order.pay_pop'), //提示
        message: self.$t('order.no_record'), //此操作将永久删除该记录, 是否继续?
        confirmButtonText: self.$t('cart.define'), //确定
        showCancelButton: true,
        cancelButtonText: self.$t('cart.cancel'), //取消
        type: 'warning',
        callback(action) {
          if (action === 'confirm') {
            self.common
              .httpPost('/api/user/delBill', {
                id: self.myBillList[index].id,
              })
              .then((ret) => {
                self.common.showMessage(
                  ret.msg,
                  ret.code == 1 ? 'success' : 'error'
                )
                if (ret.code == 1) self.myBillList.splice(index, 1)
              })
          }
        },
      })
    },
    handleForget() {
      this.payPasswordMaskShow = false
      this.setPasswordMaskShow = true
    },
    /*设置支付密码成功*/
    setPayPwdSuccess() {
      this.setPasswordMaskShow = false
      this.payPasswordMaskShow = true
    },
    /**
     * 是否设置了支付密码
     */
    userHasPayPassword() {
      return this.common.httpPost('/api/user/userHasPayPassword')
    },
    fnContactService: function () {
      this.$router.push('/feedback')
    },
    /**
     * 查询订单的物流信息
     * @param  {[type]} transport_code    [物流单号]
     * @param  {[type]} transport_company [物流公司]
     */
    httpTransportInfo: function (transport_code, transport_company) {
      var self = this
      self.common
        .httpPost('/api/ajax/transportInfo', {
          num: transport_code,
          com: transport_company,
        })
        .then((ret) => {
          if (ret.status == '200' && ret.message == 'ok') {
            self.transportInfo = ret.data
          }
        })
    },
  },
  filters: {
    showTransportCompany: function (value) {
      var index = value.indexOf('/')
      if (index == -1) return value
      else return value.substring(0, index)
    },
  },
  components: {
    'v-invoice-mask': InvoiceMask,
    'v-pay-password-mask': PayPasswordMask,
    'v-set-pay-pwd-mask': SetPayPwdMask,
  },
}
</script>
<style scoped>
.orderDetail {
  min-height: 500px;
  padding-bottom: 50px;
}

.wechat-qrcode-box {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.wechat-qrcode-box div {
  background-color: white;
  flex-flow: column;
  height: 300px;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.wechat-qrcode-box div .qecode-box {
  width: 200px;
  height: 200px;
  margin-top: 20px;
}

.wechat-qrcode-box div .close-icon {
  position: absolute;
  top: -40px;
  width: 30px;
  height: 30px;
  right: 3px;
}

.big-box {
  margin-left: 83px;
  margin-right: 83px;
  margin-top: 20px;
}

.order-box {
  border: 1px solid rgba(182, 182, 182, 0.7);
  /* margin-bottom: 40px; */
}

.order-title {
  height: 50px;
  background: rgba(247, 246, 246, 1);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.order-title .submit-btn {
  margin-right: 50px;
}

.order-title div {
  display: flex;
  align-items: center;
}

.cancel {
  font-size: 16px;
  font-weight: 400;
  color: rgba(41, 127, 171, 1);
  line-height: 18px;
  margin-right: 30px;
}

h1 {
  font-size: 16px;
  font-weight: 500;
  color: rgba(153, 153, 153, 1);
  line-height: 18px;
  margin-left: 30px;
  padding-bottom: 0 !important;
}

.order-title div p {
  font-size: 16px;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
  line-height: 24px;
  margin-left: 30px;
}

.order-body {
  height: 148px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 30px;
  margin-right: 30px;
}

.order-time {
  width: 320px;
}

.order-time > div {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
}

.red-text {
  border-bottom: 1px dashed rgba(151, 151, 151, 0.7);
}

.red-text p {
  font-size: 16px;
  font-weight: 400;
  color: rgba(225, 36, 27, 1);
  line-height: 24px;
  margin-right: 15px;
}

.red-text div {
  font-size: 16px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 24px;
}

.reason p {
  font-size: 14px;
  font-weight: 500;
  color: rgba(179, 179, 179, 1);
  line-height: 24px;
  margin-right: 15px;
}

h2 {
  font-size: 14px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 14px;
}

.go-botton {
  width: 153px;
  height: 42px;
  background: rgba(41, 127, 171, 1);
  font-size: 16px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  line-height: 42px;
  text-align: center;
}

.book-body {
  padding-left: 30px;
  padding-right: 30px;
}

.book-money {
  height: 204px;
  display: flex;
  align-items: center;
  border-bottom: 1px dashed rgba(151, 151, 151, 0.7);
}

.book-money:last-child {
  border-bottom: 0;
}

.book-money:first-child {
  margin-top: 5px;
}

.book-money > img {
  height: 144px;
  width: 144px;
  margin-right: 90px;
}

.book-name {
  width: 220px;
  min-height: 120px;
  margin-right: 150px;
}

.name-title {
  font-size: 16px;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
  line-height: 22px;
  padding-bottom: 10px;
  border-bottom: 1px dashed rgba(151, 151, 151, 0.7);
  margin-bottom: 10px;
  word-break: break-all;
  overflow: hidden;
  /* height: 57px; */
  max-height: 59px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.book-name p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  line-height: 20px;
  margin-bottom: 15px;
}

.book-name > div {
  display: flex;
  flex-wrap: wrap;
}

.book-name div div {
  width: 72px;
  height: 20px;
  border: 1px solid rgba(41, 127, 171, 0.7);
  font-size: 12px;
  font-weight: 400;
  color: rgba(41, 127, 171, 1);
  line-height: 20px;
  text-align: center;
  margin-right: 10px;
  margin-bottom: 10px;
}

/*.money-text {
            min-height: 40px;
        }*/

.money-text span {
  font-size: 14px;
  font-family: 'PingFangSC-Medium', sans-serif;
  color: #999999;
  line-height: 16px;
}

.money-text > div {
  display: flex;
  align-items: center;
}

.money-text > div > p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(179, 179, 179, 1);
  line-height: 14px;
}

.money-text > div > div {
  margin-right: 28px;
}

.money-text > div > div p {
  font-size: 14px;
  font-family: 'PingFangSC-Medium', sans-serif;
  color: #3f3f3f;
  line-height: 16px;
}

h3 {
  font-size: 14px;
  font-weight: 400;
  font-family: 'PingFangSC-Medium', sans-serif;
  color: rgba(179, 179, 179, 1);
  line-height: 16px;
  margin-left: 45px;
}

.percentage-money {
  margin-left: 200px;
}

.percentage-money p {
  font-size: 18px;
  color: #297fab;
  line-height: 18px;
  font-family: 'PingFangSC-Medium', sans-serif;
}

.percentage-money span {
  font-size: 18px;
  color: #999999;
  line-height: 18px;
  font-family: 'PingFangSC-Medium', sans-serif;
}

.percentage-money div {
  font-size: 18px;
  color: rgba(179, 179, 179, 1);
  line-height: 18px;
  margin-top: 1px;
  margin-left: 57px;
  font-family: 'PingFangSC-Medium', sans-serif;
}

.preferential-box {
  display: flex;
  justify-content: flex-end;
  margin-top: 35px;
  margin-bottom: 40px;
}

.preferential {
  padding-bottom: 5px;
  border-bottom: 1px dashed rgba(151, 151, 151, 0.7);
}

.preferential > div {
  display: flex;
  margin-bottom: 20px;
}

.preferential div > p {
  font-size: 20px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 25px;
}

h4 {
  width: 156px;
  font-size: 16px;
  font-weight: 500;
  color: rgba(153, 153, 153, 1);
  line-height: 25px;
  text-align: right;
  margin-right: 33px;
}

.preferential-add > div {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}

.preferential-add > div:last-child {
  margin-bottom: 0px;
}

.preferential-add div div {
  width: 100px;
  height: 25px;
  border: 1px solid rgba(153, 153, 153, 0.7);
  font-size: 16px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  line-height: 25px;
  text-align: center;
  margin-right: 13px;
}

.preferential-add div p {
  font-size: 18px;
  font-weight: 400;
  color: rgba(41, 127, 171, 1);
  line-height: 14px;
}

.preferential-money > div {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.preferential-money div p {
  font-size: 20px;
  font-weight: 500;
  color: rgba(225, 36, 27, 1);
  line-height: 16px;
}

.preferential-money div div {
  font-size: 18px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 16px;
}

h5 {
  font-size: 16px;
  font-weight: 500;
  color: rgba(153, 153, 153, 1);
  line-height: 18px;
  margin-right: 260px;
}

.goods {
  padding: 30px;
  display: flex;
}

.address-box {
  border: 1px solid rgba(182, 182, 182, 0.7);
  margin-bottom: 33px;
}

.address {
  width: 346px;
  border-right: 1px dashed rgba(151, 151, 151, 0.7);
}

.address div {
  margin-bottom: 14px;
  display: flex;
  align-items: center;
}

.address div h2 {
  margin-left: 10px;
  text-align: left;
  flex: 1;
}

.address div:last-child {
  margin-bottom: 0px;
}

.address div p {
  width: 75px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  line-height: 14px;
}

.state {
  width: 435px;
  padding-left: 30px;
  border-right: 1px solid rgba(151, 151, 151, 0.7);
}

.state > div {
  margin-bottom: 14px;
  display: flex;
}

.state > div:last-child {
  margin-bottom: 0px;
}

.state div > p {
  width: 100px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  line-height: 14px;
}

.state-div {
  flex: 1;
  padding-right: 15px;
}

.state-div div {
  font-size: 14px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  line-height: 14px;
  margin-top: 14px;
}

.invoice {
  padding-left: 30px;
}

.invoice div {
  margin-bottom: 14px;
  display: flex;
  align-items: center;
}

.invoice div:last-child {
  margin-bottom: 0px;
}

.invoice div p {
  width: 58px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  line-height: 14px;
}

.red-botton {
  width: 50px;
  height: 20px;
  border: 1px solid rgba(225, 36, 27, 0.7);
  font-size: 12px;
  font-weight: 400;
  color: rgba(225, 36, 27, 1);
  line-height: 20px;
  justify-content: center;
}

.textarea-box {
  height: 105px;
  padding: 30px;
  border: 1px solid rgba(182, 182, 182, 0.7);
  margin-top: 15px;
}

.textarea-box textarea {
  width: 100%;
  height: 105px;
}

.payment {
  height: 60px;
  margin-top: 30px;
  margin-bottom: 50px;
  border: 1px solid rgba(182, 182, 182, 0.7);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.payment > div {
  display: flex;
  align-items: center;
}

.payment > p {
  flex: 1;
  margin-left: 40px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(179, 179, 179, 1);
  line-height: 20px;
}

.payment-botton > div {
  text-align: right;
  margin-right: 20px;
}

.payment-botton div div {
  font-size: 14px;
  color: rgba(179, 179, 179, 1);
  line-height: 16px;
  font-family: 'PingFangSC-Medium', sans-serif;
}

.payment-botton div p {
  font-size: 22px;
  font-family: 'PingFangSC-Medium', sans-serif;
  color: rgba(225, 36, 27, 1);
  line-height: 33px;
  letter-spacing: 1px;
}

.payment-botton > p {
  width: 190px;
  font-size: 18px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  line-height: 60px;
  background: #297fab;
  text-align: center;
}

.payment-text p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.payment-redtext {
  color: rgba(225, 36, 27, 1);
  margin-left: 30px;
  margin-right: 15px;
}

/*待确认页面css*/
.reason {
  display: flex;
  align-items: center;
}

.reason div {
  font-size: 16px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 24px;
}

.reason img {
  height: 14px;
  width: 14px;
  margin-right: 7px;
}

.go-look {
  font-size: 16px;
  font-weight: 400;
  color: rgba(41, 127, 171, 1);
  line-height: 16px;
}

.again {
  font-size: 16px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  line-height: 30px;
  width: 84px;
  height: 30px;
  background: rgba(255, 255, 255, 1);
  border-radius: 2px;
  border: 1px solid rgba(153, 153, 153, 0.7);
  text-align: center;
  margin-right: 30px;
}

.blue-botton {
  width: 50px;
  height: 20px;
  border: 1px solid rgba(41, 127, 171, 0.7);
  font-size: 12px;
  font-weight: 400;
  color: rgba(41, 127, 171, 1);
  line-height: 20px;
  justify-content: center;
}

.service-state {
  margin-top: 25px;
  margin-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  justify-content: space-between;
}

.service-box {
  height: 23px;
  display: flex;
  align-items: center;
}

.service-box img {
  height: 23px;
  width: 23px;
  margin-right: 8px;
}

.service-box p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  line-height: 14px;
}

.service-time {
  padding-top: 5px;
}

.service-time div {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.service-time div:last-child {
  margin-bottom: 0px;
}

.time-title {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 14px;
}

.time-body {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 14px;
}

/*待收货时  css */
.confirm {
  width: 153px;
  height: 42px;
  background: #3687b0;
  font-size: 16px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  line-height: 42px;
  text-align: center;
}

.add-bluetext {
  font-size: 14px;
  font-weight: 400;
  color: rgba(41, 127, 171, 1);
  line-height: 20px;
  margin-left: 30px;
}

.invoice-box {
  margin: 10px 30px;
}

.add-invoice {
  display: flex;
  align-items: center;
}

.add-invoice img {
  height: 20px;
  width: 20px;
  margin-right: 20px;
}

.add-invoice > div {
  min-width: 442px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dashed rgba(182, 182, 182, 0.7);
}

.add-invoice:last-child > div {
  border-bottom: 0;
}

.add-invoice div > p {
  font-size: 12px;
  font-weight: 400;
  color: rgba(179, 179, 179, 1);
  line-height: 12px;
}

.add-invoice div div > div {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.add-invoice div div p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  line-height: 14px;
  margin-right: 20px;
}

/*已收货css        */
.orderover-box {
  border: 1px solid rgba(182, 182, 182, 0.7);
  margin-bottom: 20px;
}

.orderover-title {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.orderover-title div {
  display: flex;
  align-items: center;
}

.orderover-title div p {
  font-size: 16px;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
  line-height: 24px;
  margin-left: 30px;
}

/*支付方式*/
.mode-payment {
  border: 1px solid rgba(182, 182, 182, 0.7);
  margin-top: 40px;
}

.log-box {
  height: 165px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.log-box div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 80px;
}

.log-box div:last-child {
  margin-right: 0;
}

.dot {
  height: 20px;
  width: 20px;
}

.logo {
  height: 36px;
  width: 36px;
  margin-left: 15px;
  margin-right: 10px;
}

.log-box div p {
  font-size: 14px;
  font-weight: 500;
  color: rgba(102, 102, 102, 1);
  line-height: 20px;
}

.explain {
  font-size: 14px;
  font-weight: 400;
  color: rgba(225, 36, 27, 1);
  line-height: 46px;
  text-align: center;
}

.log-box .balance-value {
  display: flex;
  flex-flow: column;
  justify-items: center;
  align-items: flex-start;
}

.balance-value p {
  text-align: left;
}

.log-box .balance-value p:last-child {
  font-size: 14px;
  color: rgba(153, 153, 153, 1);
}

.order-code {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
}

.order-code p {
  font-size: 16px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  line-height: 14px;
}

.order-code span {
  font-size: 16px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 14px;
  margin-left: 5px;
}
.order-code div {
  font-size: 16px;
  font-weight: 400;
  color: rgba(54, 135, 176, 1);
  line-height: 14px;
}

.el-select {
  width: 100%;
}
</style>